// src/App.js

import React from 'react';
import MemoriesLanding from './components/MemoriesLanding';
import './App.css'; // Import Tailwind and custom styles

function App() {
    return <MemoriesLanding />;
}

export default App;
